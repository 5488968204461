
body {
  background: rgba(0, 35, 54) url("assets/img/back.png") no-repeat;
  background-size: cover;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
background: rgba(0, 35, 54);
}

::-webkit-scrollbar-thumb {
background: #00cbcd;
}

::-webkit-scrollbar-thumb:hover {
background: #00cbcd;
}
section {
  margin-bottom: 100px;
}
.news .image-container {
  position: relative;
  height: 15rem; 
}

.news .image-container .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3;
}

.news .image-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 10px;
  flex-direction: column;
}
.news .card {
  transition: 2s;
}
.news .card:hover {
  transform: scale(1.04);
}

.events .card {
  transition: 2s;
}
.events .card:hover {
  transform: scale(1.04);
}
.btn-join {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}
.play-button {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  width: 40px;
  height: 40px;
}
.home {
  height: 90vh;
  align-items: center;
  display: flex;
}
#preloader {
  display: flex; /* Use flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  flex-direction: column; /* Stack children vertically */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, rgba(0, 35, 54), rgba(0, 35, 54));
  z-index: 9999999;
}


#preloader #status {
  width: 100%; /* Full width to center the spinner */
  display: flex; /* Use flexbox */
  justify-content: center; /* Center horizontally */
}

#preloader #status .spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 auto; /* Center the spinner */
}

#preloader #status .spinner .double-bounce1, #preloader #status .spinner .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #00cbcd;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

#preloader #status .spinner .double-bounce2 {
  animation-delay: -1.0s;
}


@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
.navbar-brand img {
  padding: 10px;
  max-width: 200px;
}
body .text-primary {
  color: #00cbcd !important;
} 
a{
  text-decoration: none !important;
}
body .border-primary{ 
  border: 1px solid #00cbcd !important;
  opacity: 1;
  box-shadow: 0 0 2px #1bd1d1, 0 0 5px #1bd1d1, 0 0 5px #1bd1d1;
}

.menu {
  background-color: rgba(0, 35, 54, 0.486);
  border: 1px solid #00cbcd57;
  box-shadow: 0px 0px 1px 1px #00cbcd;
  border-radius: 20px;
  padding: 0px 10px 0px 10px;
  color: #fff;
  min-width: 50%;
  justify-content: center;
  text-align: center;
}
footer {
  padding: 30px;
}
.nav-link {
  padding: 10px !important;
  color: #fff !important;
  text-align: center;
  text-transform: uppercase;
  transition: color 1.3s;
}
.nav-link:hover {
  color: #00cbcd !important;
  text-shadow: 0 0 5px #00ffff;
  transition: 1.3s;
}
.nav-link:not(:hover) {
  color: rgba(0, 0, 0, 0.5);
  text-shadow: 0 0 5px transparent;
  transition: 1.3s;
}

.navbar-toggler {
  background: #00cbcd !important;
  border: 1px solid #00cbcd !important;
  color: #fff !important;
}
.active{
  color: #00cbcd !important;
  text-shadow: 0 0 5px #00ffff;
}
.btn-join{
  background-color: transparent !important;
  color: #fff !important;
  border-radius: 20px !important;
  width: 9rem !important;
  text-transform: uppercase;
  font-weight: bold !important;
  height: 2.7rem;
  box-shadow: 0 0 4px #1bd1d1, 0 0 10px #1bd1d1, 0 0 10px #1bd1d1;
}

.btn-primary{
  background-color: transparent !important;
  color: #1bd1d1 !important;
  border-radius: 5px !important;
  text-transform: uppercase;
  font-weight: bold !important;
  border: 1px solid #1bd1d1 !important;
  box-shadow: 0 0 4px #1bd1d1, 0 0 10px #1bd1d1, 0 0 10px #1bd1d1;
}  
.btn-primary:hover {
  color: #fff !important;
  background-color: #00cbcd !important;
  box-shadow: 0 0 12px #00cbcd;
  text-shadow: 0 0 3px #00cbcd;
  transition: 1.2s;
}
.btn-primary:not(:hover) {
  transition: 1.3s;
}
.btn-join:hover {
  color: #fff;
  background-color: #00cbcd !important;
  box-shadow: 0 0 12px #00cbcd;
  text-shadow: 0 0 3px #00cbcd;
  transition: 1.2s;
}
.btn-join:not(:hover) {
  transition: 1.3s;
}

.btn-icon {
  font-size: 14px !important;
  background-color: transparent !important;
  color: #fff !important;
  border-radius: 30px !important;
  font-weight: bold !important;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  box-shadow: 0 0 5px #1bd1d1, 0 0 10px #1bd1d1, 0 0 10px #1bd1d1;
}

.btn-icon-stats {
  float: left;
  width: 40px;
  height: 40px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  line-height: 42px;
  margin-right: 18px;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 0 5px #1bd1d1, 0 0 10px #1bd1d1, 0 0 10px #1bd1d1;
}
.card-stats {
  height: 75px !important;
}
.number-stats {
  float: left;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  line-height: 42px;
  margin-right: 18px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}
.text-stats {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 20px;
  text-transform: uppercase;
}
.btn-icon-youtube {
  color: #cc181e !important;
  box-shadow: 0 0 5px #cc181e, 0 0 10px #cc181e, 0 0 10px #cc181e;
}
.btn-icon-youtube:hover {
  color: #fff !important;
  transition: 1.2s;
  background-color: #cc181e !important;
  border: 1px solid #cc181e !important;
}
.btn-icon-youtube:not(:hover) {
  transition: 1.3s;
}
.btn-icon-twitch {
  color: #9146ff !important;
  box-shadow: 0 0 5px #9146ff, 0 0 10px #9146ff, 0 0 10px #9146ff;
}

.btn-icon-twitch:hover {
  color: #fff !important;
  transition: 1.2s;
  background-color: #9146ff !important;
  border: 1px solid #9146ff !important;
}
.btn-icon-twitch:not(:hover) {
  transition: 1.3s;
}
.btn-icon-vk {
  color: #597ba0 !important;
  box-shadow: 0 0 5px #597ba0, 0 0 10px #597ba0, 0 0 10px #597ba0;
}
.btn-icon-vk:hover {
  color: #fff !important;
  transition: 1.2s;
  background-color: #597ba0 !important;
  border: 1px solid #597ba0 !important;
}
.btn-icon-vk:not(:hover) {
  transition: 1.3s;
}
.btn-icon-tmp {
  color: #b92025 !important;
  box-shadow: 0 0 5px #b92025, 0 0 10px #b92025, 0 0 10px #b92025;
}
.btn-icon-tmp:hover {
  color: #fff !important;
  transition: 1.2s;
  background-color: #b92025 !important;
  border: 1px solid #b92025 !important;
}
.btn-icon-tmp:not(:hover) {
  transition: 1.3s;
}
.btn-icon-instagram {
  color: #ff0069 !important;
  box-shadow: 0 0 5px #ff0069, 0 0 10px #ff0069, 0 0 10px #ff0069;
}
.btn-icon-instagram:hover {
  color: #fff !important;
  transition: 1.2s;
  background-color: #ff0069 !important;
  border: 1px solid #ff0069 !important;
}
.btn-icon-instagram:not(:hover) {
  transition: 1.3s;
}
.btn-discord{
  background-color: transparent !important;
  color: #fff !important;
  border-radius: 20px !important;
  width: 9rem !important;
  text-transform: uppercase;
  font-weight: bold !important;
  height: 2.7rem;
  box-shadow: 0 0 5px #1bd1d1, 0 0 10px #1bd1d1, 0 0 10px #1bd1d1;
}


.btn-discord:hover {
  color: #00cbcd !important;
  box-shadow: 0 0 5px #00cbcd !important;
  text-shadow: 0 0 5px #00cbcd !important;
  transition: 1.2s;
}
.btn-discord:not(:hover) {
  transition: 1.3s;
}
.btn-apply{
  background-color: #00cbcd !important;
  color: #fff !important;
  border-radius: 20px !important;
  width: 9rem !important;
  text-transform: uppercase;
  font-weight: bold !important;
  height: 2.7rem;
  box-shadow: 0 0 4px #1bd1d1, 0 0 10px #1bd1d1, 0 0 10px #1bd1d1;
}


.btn-apply:hover {
  color: #00cbcd;
  background-color: transparent !important;
  box-shadow: 0 0 12px #00cbcd;
  text-shadow: 0 0 3px #00cbcd;
  transition: 0.7s;
}
.btn-apply:not(:hover) {
  transition: 0.7s;
}
.play-card .image-container {
  position: relative;
  border: 2px solid #00cbcd;
  border-radius: 10px;
}

.play-card .image-container img {
  width: 100%; /* ensure the image takes the full width of the container */
  opacity: 0.4; /* set the opacity of the image */
}

.play-card .image-container .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* center the button */
  z-index: 2;
}

@media (max-width: 576px) {
  .menu {
      width: 90%;
  }
}


@media (min-width: 768px) { 

}

@media (min-width: 992px) { 
  
}

@media (min-width: 1200px) { 
  
}